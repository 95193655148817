<template>
  <div class="home">
    <img src="../assets/mirror-battle-menu-icon.png"
        class="home__icon"
        style="">
    <h1 class="title">Mirror Battle</h1>
    <div class="actions">
      <mb-button
        icon="el-icon-arrow-right"
        @click="launchAIGame">Start quick game</mb-button>
      <mb-button
        icon="el-icon-user"
        type="secondary"
        @click="playWithFriends">Play with friends</mb-button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MbButton from '../components/library/MbButton.vue';

export default {
  components: {
    MbButton,
  },
  methods: {
    ...mapActions(['joinAIGame']),
    launchAIGame() {
      this.joinAIGame();
      this.$amplitude.logEvent('ClickedStartQuickGame');
      this.$router.push({ name: 'AIGame' });
    },
    playWithFriends() {
      this.$amplitude.logEvent('ClickedPlayWithFriends');
      this.$router.push({ name: 'New' });
    },
  },
};
</script>

<style lang="scss">
@mixin media-min($_min-width) {
    @media screen and (min-width: $_min-width) {
        &{ @content; }
    }
}

$large-screen: 768px;

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #86BBD8;
  .home__icon {
    height: 64px;
    width: 64px;
    border-radius: 64px;
    margin: 12px;
    @include media-min($large-screen) {
      height: 100px;
      width: 100px;
      border-radius: 100px;
    }
  }
  .title {
    color: #2F4858;
    font-size: 3rem;
    margin: 0;
    @include media-min($large-screen) {
      font-size: 5rem;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    .mb-button {
      margin: 4px 0;
    }
    @include media-min($large-screen) {
      flex-direction: row;
      .mb-button {
        margin: 0 4px;
      }
    }
  }
}
</style>
