<template>
  <div id="app">
    <div class="content">
      <transition :name="transitionName" appear>
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$amplitude.logEvent('OpenApp');
  },
  data() {
    return {
      transitionName: null,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name.toLowerCase().includes('game')) {
        this.transitionName = 'slide-up';
        return;
      }
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = fromDepth < toDepth ? 'slide-up' : 'slide-down';
    },
  },
};
</script>

<style lang="scss">
$charcoal: #2F4858;
$queen-blue: #33658A;
$dark-sky-blue: #86BDD8;
$honey-yellow: #F6AE2D;
$red: #FF0000;

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $charcoal;
}
.slide-up-enter-to, .slide-up-leave-to {
  transform: translateY(-100vh);
}
.slide-up-leave-active, .slide-up-enter-active, .slide-down-leave-active, .slide-down-enter-active {
  transition: transform 1s;
}
.slide-down-leave-to {
  transform: translateY(100vh);
}
.slide-down-enter {
  transform: translateY(-200vh);
}
.slide-down-enter-to {
  transform: translateY(-100vh);
}
</style>
