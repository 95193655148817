<template>
  <el-button class="mb-button"
    @click="$emit('click', $event)"
    :icon="icon"
    :size="size"
    :disabled="disabled"
    :class="{
      'mb-button--primary': type==='primary',
      'mb-button--secondary': type==='secondary',
    }">
    <slot />
  </el-button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss">
.mb-button {
  border-radius: 32px;
  border: 2px solid white;
  background-color: white;
  font-weight: bold;
  &.mb-button--primary {
    color: #2F4858;
    background-color: white;
  }
  &.mb-button--secondary {
    color: white;
    background-color: transparent;
  }
  &[disabled] {
    color: gray;
    background-color: rgba(211,211,211, 0.5);
    border: 2px solid rgba(211,211,211, 0.5);
  }
}
</style>
