import Vue from 'vue';
import Vuex from 'vuex';
import Peer from 'peerjs';
import map0 from '../data/map_0.json';
import generateStartPositionsFromMap from '../helpers/game-functions';
import mode from '../helpers/playModes';
import status from '../helpers/connectionStatuses';
import otherPlayer from '../helpers/helpers';

Vue.use(Vuex);

function makeBiGram(name) {
  if (name.length < 2) {
    return name.toUpperCase();
  }
  return name.slice(0, 2).toUpperCase();
}

function getNumberOfVisits() {
  if (!localStorage.getItem('NumberOfVisits')) {
    localStorage.setItem('NumberOfVisits', 0);
  }
  const numberOfVisits = localStorage.getItem('NumberOfVisits');
  localStorage.setItem('NumberOfVisits', numberOfVisits + 1);
  return numberOfVisits;
}

export default new Vuex.Store({
  state: {
    positionsHistory: [],
    currentHistoryIndex: -1,
    currentPlayer: null,
    players: {
      a: {
        name: 'PA',
        color: '#F6AE2D',
      },
      b: {
        name: 'PB',
        color: '#33658A',
      },
    },
    playMode: mode.DEVICE,
    localPlayer: null,
    peer: null,
    connection: null,
    connectionStatus: status.SUCCESS,
    connectionMessage: '',
    startPlayer: (Math.random() < 0.5 ? 'a' : 'b'),
    gameCode: null,
    displayRules: getNumberOfVisits() < 2,
  },
  getters: {
    positions(state) {
      if (!state.positionsHistory) {
        return null;
      }
      return state.positionsHistory.slice(
        state.currentHistoryIndex,
      )[0];
    },
    map() {
      return map0;
    },
    goBackAllowed(state) {
      return (-state.currentHistoryIndex) < state.positionsHistory.length;
    },
    goForwardAllowed(state) {
      return (state.currentHistoryIndex < -1);
    },
    playersPieces(state, getters) {
      const pieces = {
        a: {
          stopper: 0,
          mirror: 0,
          spreader: 0,
          'super-spreader': 0,
        },
        b: {
          stopper: 0,
          mirror: 0,
          spreader: 0,
          'super-spreader': 0,
        },
      };
      getters.positions.forEach((line) => {
        line.forEach((cell) => {
          if (cell.type) {
            pieces[cell.player][cell.type] += 1;
          }
        });
      });
      return pieces;
    },
    currentPlayer(state) {
      return state.currentPlayer;
    },
    playersColor(state) {
      return {
        a: state.players.a.color,
        b: state.players.b.color,
      };
    },
    playersName(state) {
      return {
        a: state.players.a.name,
        b: state.players.b.name,
      };
    },
    playersBiGram(state) {
      if (state.playMode === mode.AI) {
        return {
          a: 'You',
          b: 'A.I.',
        };
      }
      return {
        a: makeBiGram(state.players.a.name),
        b: makeBiGram(state.players.b.name),
      };
    },
    playerPieces(state, getters) {
      return (player) => getters.playersPieces[player];
    },
    playerRemainingPieces(state, getters) {
      return (player) => ({
        stopper: getters.map.pieces.stopper === 'infinity' ? 'infinity'
          : getters.map.pieces.stopper - getters.playerPieces(player).stopper,
        mirror: getters.map.pieces.mirror === 'infinity' ? 'infinity'
          : getters.map.pieces.mirror - getters.playerPieces(player).mirror,
        spreader: getters.map.pieces.spreader === 'infinity' ? 'infinity'
          : getters.map.pieces.spreader - getters.playerPieces(player).spreader,
        'super-spreader': getters.map.pieces['super-spreader'] === 'infinity' ? 'infinity'
          : getters.map.pieces['super-spreader'] - getters.playerPieces(player)['super-spreader'],
      });
    },
    isHost(state) {
      return (state.playMode === mode.ONLINE && state.localPlayer === 'a');
    },
    connectionRequiresAttention(state) {
      return (state.playMode !== mode.DEVICE) && (state.connectionStatus !== status.SUCCESS);
    },
    connectionExists(state) {
      return state.connection !== null;
    },
  },
  mutations: {
    PUSH_NEW_POSITIONS(state, positions) {
      state.positionsHistory.push(positions);
      state.currentHistoryIndex = -1;
      state.currentPlayer = otherPlayer(state.currentPlayer);
    },
    GO_BACK(state) {
      state.currentHistoryIndex -= 1;
      state.currentPlayer = otherPlayer(state.currentPlayer);
    },
    GO_FORWARD(state) {
      state.currentHistoryIndex += 1;
      state.currentPlayer = otherPlayer(state.currentPlayer);
    },
    RESET_POSITIONS(state) {
      state.positionsHistory = [generateStartPositionsFromMap(map0)];
    },
    SET_CURRENT_PLAYER(state, currentPlayer) {
      state.currentPlayer = currentPlayer;
    },
    SET_LOCAL_PLAYER(state, localPlayer) {
      state.localPlayer = localPlayer;
    },
    SET_MODE(state, m) {
      if (!(m in mode)) {
        throw new Error(`${m} is not a valid play mode.`);
      }
      state.playMode = m;
    },
    SET_PEER(state, peer) {
      state.peer = peer;
    },
    SET_CONNECTION(state, connection) {
      state.connection = connection;
    },
    UPDATE_CONNECTION_STATUS(state, payload) {
      state.connectionStatus = payload.status;
      state.connectionMessage = payload.message;
    },
    SET_PLAYER_A_NAME(state, name) {
      state.players.a.name = name;
    },
    SET_PLAYER_B_NAME(state, name) {
      state.players.b.name = name;
    },
    SET_START_PLAYER(state, startPlayer) {
      state.startPlayer = startPlayer;
    },
    SET_GAME_CODE(state, code) {
      state.gameCode = code;
    },
    DISPLAY_RULES(state) {
      state.displayRules = true;
    },
    HIDE_RULES(state) {
      state.displayRules = false;
    },
  },
  actions: {
    send({ state }, payload) {
      // console.log('send', payload);
      state.connection.send(payload);
    },
    pushNewPositions({ commit, state, dispatch }, positions) {
      if (state.playMode === mode.ONLINE) {
        dispatch('send', {
          event: 'push-new-positions',
          data: positions,
        });
      }
      commit('PUSH_NEW_POSITIONS', positions);
    },
    goBack({ commit, state, dispatch }) {
      if (state.playMode === mode.ONLINE) {
        dispatch('send', {
          event: 'go-back',
        });
      }
      commit('GO_BACK');
    },
    goForward({ commit, state, dispatch }) {
      if (state.playMode === mode.ONLINE) {
        dispatch('send', {
          event: 'go-forward',
        });
      }
      commit('GO_FORWARD');
    },
    resetPositions({
      commit, state, dispatch, getters,
    }, playerToStart = null) {
      const startPlayer = playerToStart || otherPlayer(state.startPlayer);
      if (state.playMode === mode.ONLINE) {
        dispatch('send', {
          event: 'reset-positions',
          data: getters.isHost ? startPlayer : playerToStart,
        });
      }
      commit('SET_START_PLAYER', startPlayer);
      commit('RESET_POSITIONS');
      commit('SET_CURRENT_PLAYER', startPlayer);
    },
    updatePlayerName({ commit }, payload) {
      if (payload.player === 'a') {
        commit('SET_PLAYER_A_NAME', payload.newName);
        return;
      }
      commit('SET_PLAYER_B_NAME', payload.newName);
    },
    setupPeer({ commit, dispatch }, peer) {
      peer.on('error', (err) => dispatch('handlePeerErrors', err));
      commit('SET_PEER', peer);
    },
    setupConnection({ commit, dispatch }, connection) {
      connection.on('data', (data) => dispatch('handleConnectionData', data));
      connection.on('error', (err) => {
        commit('UPDATE_CONNECTION_STATUS', {
          status: status.ERROR,
          message: err,
        });
      });
      connection.on('close', () => {
        commit('UPDATE_CONNECTION_STATUS', {
          status: status.ERROR,
          message: 'Connection closed.',
        });
      });
      commit('SET_CONNECTION', connection);
    },
    handlePeerErrors({ commit }, err) {
      // console.log('peer-error', err);
      switch (err.type) {
        case 'peer-unavailable':
          commit('UPDATE_CONNECTION_STATUS', {
            status: status.ERROR,
            message: 'Game not found.',
          });
          break;
        case 'network':
          commit('UPDATE_CONNECTION_STATUS', {
            status: status.ERROR,
            message: 'Network error.',
          });
          break;
        case 'disconnected':
          break;
        default:
          commit('UPDATE_CONNECTION_STATUS', {
            status: status.ERROR,
            message: 'Connection error.',
          });
      }
    },
    handleConnectionData({ commit, dispatch, state }, data) {
      // console.log('peer-data', data);
      switch (data.event) {
        case 'join-feedback':
          commit('UPDATE_CONNECTION_STATUS', {
            status: data.data.status,
            message: data.data.message,
          });
          if (data.data.status === status.SUCCESS) {
            commit('SET_PLAYER_A_NAME', data.data.playerName);
          }
          break;
        case 'player-b-ready':
          commit('SET_PLAYER_B_NAME', data.data.playerName);
          commit('UPDATE_CONNECTION_STATUS', {
            status: data.data.status,
            message: data.data.message,
          });
          dispatch('resetPositions');
          break;
        case 'reset-positions':
          commit('SET_START_PLAYER', data.data || state.startPlayer);
          commit('RESET_POSITIONS');
          commit('SET_CURRENT_PLAYER', data.data || state.startPlayer);
          break;
        case 'push-new-positions':
          commit('PUSH_NEW_POSITIONS', data.data);
          break;
        case 'go-back':
          commit('GO_BACK', data.data);
          break;
        case 'go-forward':
          commit('GO_FORWARD', data.data);
          break;
        case 'update-player-name':
          dispatch('updatePlayerName', data.data);
          break;
        default:
          commit('UPDATE_CONNECTION_STATUS', {
            status: status.ERROR,
            message: 'Unknown connection event.',
          });
      }
    },
    createOnlineGame({
      commit, state, getters, dispatch,
    }, data) {
      commit('UPDATE_CONNECTION_STATUS', {
        status: status.INFO,
        message: 'Checking connection...',
      });
      commit('SET_GAME_CODE', data.code);
      commit('SET_MODE', mode.ONLINE);
      commit('SET_LOCAL_PLAYER', 'a');
      commit('SET_PLAYER_A_NAME', data.name);
      const peer = new Peer(`mirror-battle-${data.code}`);
      dispatch('setupPeer', peer);
      peer.on('open', () => {
        commit('UPDATE_CONNECTION_STATUS', {
          status: status.INFO,
          message: 'Waiting for someone to join.',
        });
      });
      commit('SET_CONNECTION', null);
      peer.on('connection', (connection) => {
        if (state.connection) {
          connection.on('open', () => {
            connection.send({
              event: 'join-feedback',
              data: {
                status: status.ERROR,
                message: 'Room is already full.',
              },
            });
          });
          return;
        }
        connection.on('open', () => {
          dispatch('setupConnection', connection);
          dispatch('send', {
            event: 'join-feedback',
            data: {
              status: status.SUCCESS,
              message: `${getters.playersName.a} is waiting for you! :)`,
              playerName: getters.playersName.a,
            },
          });
        });
      });
    },
    joinOnlineGame({ commit, dispatch }, code) {
      // console.log('joinOnlineGame');
      commit('UPDATE_CONNECTION_STATUS', {
        status: status.INFO,
        message: 'Connecting to game...',
      });
      commit('SET_GAME_CODE', code);
      commit('SET_MODE', mode.ONLINE);
      commit('SET_LOCAL_PLAYER', 'b');
      const peer = new Peer();
      peer.on('open', () => {
        // console.log('joinOnlineGame - peer open');
        // console.log('code', `mirror-battle-${code}`);
        const connection = peer.connect(`mirror-battle-${code}`);
        connection.on('open', () => {
          // console.log('joinOnlineGame - peer open - connection open');
          dispatch('setupConnection', connection);
        });
      });
      dispatch('setupPeer', peer);
    },
    joiningPlayerReady({ dispatch, commit }, playerName) {
      commit('SET_PLAYER_B_NAME', playerName);
      dispatch('send', {
        event: 'player-b-ready',
        data: {
          status: status.SUCCESS,
          playerName,
          message: `${playerName} just joined the game! :)`,
        },
      });
    },
    joinAIGame({ commit }) {
      commit('UPDATE_CONNECTION_STATUS', {
        status: status.SUCCESS,
        message: '',
      });
      commit('SET_MODE', mode.AI);
      commit('SET_LOCAL_PLAYER', 'a');
    },
    createOfflineGame({ commit }, payload) {
      commit('SET_MODE', mode.DEVICE);
      commit('SET_PLAYER_A_NAME', payload.playerA);
      commit('SET_PLAYER_B_NAME', payload.playerB);
    },
  },
  modules: {
  },
});
