export default function generateStartPositionsFromMap(map) {
  const pos = [];
  for (let i = 0; i < map.height; i += 1) {
    pos.push([]);
    for (let j = 0; j < map.width; j += 1) {
      pos[i].push({
        type: null,
        direction: null,
        walls: (`${i},${j}` in map.walls) ? map.walls[`${i},${j}`] : [],
        player: null,
      });
    }
  }
  return pos;
}
