import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/game/quick',
    name: 'AIGame',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Board.vue'),
  },
  {
    path: '/game/new',
    name: 'New',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LaunchGame.vue'),
  },
  {
    path: '/game/offline/new',
    name: 'NewOffline',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LaunchOffline.vue'),
  },
  {
    path: '/game/online/new',
    name: 'NewOnline',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LaunchOnline.vue'),
  },
  {
    path: '/game/online/new/create',
    name: 'OnlineCreate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OnlineCreate.vue'),
  },
  {
    path: '/game/online/new/join',
    name: 'OnlineJoin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OnlineJoin.vue'),
  },
  {
    path: '/game/online/new/create-or-join/wait',
    name: 'WaitingRoom',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WaitingRoom.vue'),
  },
  {
    path: '/game/offline',
    name: 'OfflineGame',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Board.vue'),
  },
  {
    path: '/:code',
    name: 'OnlineGameFromURL',
    redirect: (to) => {
      store.dispatch('joinOnlineGame', to.params.code);
      return { name: 'WaitingRoom' };
    },
  },
  {
    path: '/game/:code',
    name: 'OnlineGame',
    component: () => import(/* webpackChunkName: "about" */ '../views/Board.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
