import Vue from 'vue';
import VueAmplitude from 'vue-amplitude-js';
import Hotjar from 'vue-hotjar';
import ElementUI from 'element-ui';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueAmplitude, { apiKey: '2827c0d51989dd6b6265f26580488145' });
Vue.use(ElementUI);
Vue.use(Hotjar, {
  id: '2634420',
  isProduction: process.env.NODE_ENV === 'production',
});
Sentry.init({
  Vue,
  dsn: 'https://4d04f131ac60489eb35320d57cc49a7d@o1032788.ingest.sentry.io/5999740',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        'localhost',
        'mirror-battle.com',
        'mirror-battle.de',
        'invghpu4h8.execute-api.eu-west-3.amazonaws.com',
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
